import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'components/Markdown'

const Text = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner">
        <div className="column--measure-medium">
          <h2 className="headline headline--left">{props.title}</h2>
          <Markdown>{props.text}</Markdown>
        </div>
      </div>
    </div>
  )
}

Text.propTypes = { title: PropTypes.string, text: PropTypes.string }

export default Text
